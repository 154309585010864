import { SidebarPortal } from '@plone/volto/components';
import { Data } from './Data';
import { View } from './View';

export const Edit = (props) => {
  const { data, block, onChangeBlock, selected } = props;
  return (
    <>
      <View {...props} isEditMode />
      <SidebarPortal selected={selected}>
        <Data
          {...props}
          data={data}
          block={block}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
    </>
  );
};
