import { MAP_ICONS } from './configIcons';
import { Icon } from '@plone/volto/components';

export const RenderIcon = ({ name, size = '40px' }) => {
  const icon = MAP_ICONS[name];
  if (icon) {
    return <Icon name={icon} size={size} />;
  }
  return null;
};
