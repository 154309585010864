import { BlockChooser, Icon } from '@plone/volto/components';
import addSVG from '@plone/volto/icons/add.svg';
import React from 'react';
import { Button } from 'semantic-ui-react';
import useOutsideClick from '../../helpers/useOutsideClick/useOutsideClick';

export const NewBlockAddButton = (props) => {
  const { allowedBlocks, block, onChangeGridItem } = props;
  const ref = React.useRef();
  const [isOpenMenu, setOpenMenu] = React.useState(false);

  useOutsideClick(ref, () => setOpenMenu(false));

  return (
    <>
      {isOpenMenu ? (
        <div ref={ref}>
          <BlockChooser
            onMutateBlock={(block, value) => onChangeGridItem(value)}
            currentBlock={block}
            showRestricted
            allowedBlocks={allowedBlocks}
          />
        </div>
      ) : (
        <Button
          basic
          icon
          onClick={() => setOpenMenu(true)}
          className="add-block-button"
          aria-label={`Add block`}
        >
          <Icon name={addSVG} className="circled" size="24px" />
        </Button>
      )}
    </>
  );
};
