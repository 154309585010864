import { Edit } from './Edit.jsx';
import { View } from './View';
import bookmarkSquareSVG from '../../assets/icons/bookmark-square.svg';

export const iconTextSectionBlock = {
  id: '__iskra-icon-text-section',
  title: 'Iskra icon text',
  icon: bookmarkSquareSVG,
  group: 'common',
  view: View,
  edit: Edit,
  restricted: false,
  mostUsed: true,
  sidebarTab: 1,
  blockHasOwnFocusManagement: true,
  security: {
    addPermission: [],
    view: [],
  },
  allowedBlocks: ['slate'],
};
