import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { STYLE_VARIANT_WITH_SHADOW } from './schema';
import cx from 'classnames';
import { View as IconView } from '../Icon/View';

export const OneColumnTemplate = ({ data, isEditMode }) => {
  const hasLink = data.link && data.link.length > 0;

  return (
    <div
      className={cx('iskra-generic-card-wrapper flex flex-direction-column', {
        'iskra-generic-card-wrapper__with-shadow':
          data.style_variant === STYLE_VARIANT_WITH_SHADOW,
        'align-items-center text-align-center': data.center_text,
      })}
    >
      <IconView data={data} />
      <div className="theme-body1-text text-font-weight-bold">
        <span>{data.title}</span>
      </div>
      <div className="iskra-card-subtitle with-margin">
        <div
          className="richtext widget"
          dangerouslySetInnerHTML={{
            __html: data?.description?.data ?? '',
          }}
        />
      </div>

      {hasLink && (
        <div className="iskra-card-subtitle">
          <ConditionalLink
            to={
              data.link.length > 0 ? flattenToAppURL(data.link[0]['@id']) : ''
            }
            condition={!isEditMode}
          >
            <span> {data.linkText}</span>
          </ConditionalLink>
        </div>
      )}
    </div>
  );
};
