import { View as IconView } from '../Icon/View';
export const View = (props) => {
  return (
    <div className="wrapper-icon-text-section">
      <IconView data={props.data} />
      <div
        className="richtext widget ml-8"
        dangerouslySetInnerHTML={{
          __html: props.data?.text?.data ?? '',
        }}
      />
    </div>
  );
};
