import { defineMessages } from 'react-intl';
import { addStyling } from '@plone/volto/helpers';

const messages = defineMessages({
  IskraAccordionBlock: {
    id: 'Iskra Accordion Block',
    defaultMessage: 'Iskra Accordion Block',
  },
  Accordion: {
    id: 'Accordion',
    defaultMessage: 'Accordion',
  },
  Options: {
    id: 'Options',
    defaultMessage: 'Options',
  },
  Title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  friendly_name: {
    id: 'Friendly name',
    defaultMessage: 'Friendly name',
  },
  collapsed: {
    id: 'Collapsed by default',
    defaultMessage: 'Collapsed by default',
  },
  non_exclusive: {
    id: 'Non exclusive',
    defaultMessage: 'Non exclusive',
  },
  non_exclusive_description: {
    id: 'Allow multiple panels open at a time',
    defaultMessage: 'Allow multiple panels open at a time',
  },
  Theme: {
    id: 'Theme',
    defaultMessage: 'Theme',
  },
  ThemeHelp: {
    id: 'Accordion theme',
    defaultMessage: 'Accordion theme',
  },
  ThemePrimary: {
    id: 'Primary',
    defaultMessage: 'Primary',
  },
  ThemeSecondary: {
    id: 'Secondary',
    defaultMessage: 'Secondary',
  },
  ThemeTertiary: {
    id: 'Tertiary',
    defaultMessage: 'Tertiary',
  },
  styling: {
    id: 'Styling',
    defaultMessage: 'Styling',
  },
  align: {
    id: 'Align',
    defaultMessage: 'Align',
  },
  margin: {
    id: 'Margin',
    defaultMessage: 'Margin',
  },
});

export const AccordionSchema = {
  title: 'Accordion',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['panel_title'],
    },
  ],
  properties: {
    panel_title: {
      title: 'Accordion title',
    },
  },
  required: [],
};

export const AccordionBlockSchema = ({ intl }) => {
  const schema = {
    title: intl.formatMessage(messages.IskraAccordionBlock),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['data'],
      },
      {
        id: 'options',
        title: intl.formatMessage(messages.Options),
        fields: ['title', 'collapsed', 'non_exclusive'],
      },
    ],
    properties: {
      title: {
        title: intl.formatMessage(messages.Title),
        description: intl.formatMessage(messages.friendly_name),
        type: 'string',
      },
      data: {
        title: intl.formatMessage(messages.Accordion),
        type: 'panels',
        schema: AccordionSchema,
      },
      collapsed: {
        title: intl.formatMessage(messages.collapsed),
        type: 'boolean',
        default: true,
      },
      non_exclusive: {
        title: intl.formatMessage(messages.non_exclusive),
        description: intl.formatMessage(messages.non_exclusive_description),
        type: 'boolean',
        default: true,
      },
    },
    required: ['title'],
  };

  return schema;
};

// Exemple esquema de styling, no s'utilitza per ara
export const AccordionStylingSchema = (props) => {
  const { intl } = props;
  const schema = addStyling(props);
  schema.properties.styles.schema = {
    title: intl.formatMessage(messages.Accordion),
    block: '__iskra-accordion',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['align', 'margin'],
      },
    ],
    properties: {
      align: {
        widget: 'style_align',
        title: intl.formatMessage(messages.align),
        actions: ['left', 'right', 'center'],
      },
      margin: {
        widget: 'custom_quad_size',
        title: intl.formatMessage(messages.margin),
      },
    },
    required: [],
  };
  return schema;
};
