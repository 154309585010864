/**
 * Home container.
 * @module components/theme/NotFound/NotFound
 */

import React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { Container } from 'semantic-ui-react';
import { withServerErrorCode } from '@plone/volto/helpers/Utils/Utils';
import { notFoundMessages as messages } from '../../../../messages';
/**
 * Not found function.
 * @function NotFound
 * @returns {string} Markup of the not found page.
 */
const NotFound = ({ intl }) => {
  return (
    <Container>
      <div style={{ maxWidth: '700px', margin: '0 auto' }}>
        <div className="mt-40">
          <h1 className="theme-title-big-text text-align-center text-font-weight-bold">
            {intl.formatMessage(messages.notFoundTitle)}
          </h1>
        </div>

        <p className="theme-title-small-text text-align-center theme-gray-text-color mt-40">
          {intl.formatMessage(messages.notFoundDescription)}
        </p>
        <div className="mt-40 mb-40">
          <img src="/not_found_image.svg" width="100%" alt="Not found page" />
        </div>
      </div>
    </Container>
  );
};

export default compose(withServerErrorCode(404), injectIntl)(NotFound);
