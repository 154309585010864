import { QuadSizeWidget } from 'web-publica-iskra-addons/components';
const applyConfig = (config) => {
  config.settings = {
    ...config.settings,
    isMultilingual: true,
    supportedLanguages: ['ca', 'es', 'en'],
    defaultLanguage: 'ca',
    DSGVOBanner: {
      ...(config.settings.DSGVOBanner || {}),
      trackingId: 'G-QD8NYNM06L',
      modules: ['tracking'],
      privacy_url: {
        ca: 'ca/politica-de-privacitat',
        es: 'es/politica-de-privacidad',
        en: 'en/privacy-police',
      },
    },
  };
  config.blocks.blocksConfig.__grid.gridAllowedBlocks = [
    'teaser',
    'image',
    'slate',
    '__iskra-card',
    '__iskra-about-card',
    '__iskra-group',
  ];

  config.widgets = {
    ...config.widgets,
    id: {
      ...config.widgets.id,
    },
  };
  config.widgets.widget.quad_size = QuadSizeWidget;
  config.widgets.widget.custom_quad_size = QuadSizeWidget;
  config.blocks.requiredBlocks = [];

  return config;
};

export default applyConfig;
