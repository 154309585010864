import accordionSVG from '@plone/volto/icons/list-arrows.svg';
import { Edit } from './Edit';
import { View } from './View';

export const accordionBlock = {
  id: '__iskra-accordion',
  title: 'Iskra Accordion',
  icon: accordionSVG,
  group: 'common',
  view: View,
  edit: Edit,
  restricted: false,
  mostUsed: false,
  blockHasOwnFocusManagement: true,
  sidebarTab: 1,
  defaults: {},
  security: {
    addPermission: [],
    view: [],
  },
};
