// import config from '@plone/volto/registry';
// import React from 'react';
// import AnimateHeight from 'react-animate-height';

// export const View = ({ data }) => {
//   const ButtonView = config.blocks.blocksConfig['__iskra-button'].view;
//   const [isContentVisible, setIsContentVisible] = React.useState(false);
//   return (
//     <>
//       <div className="iskra-accordion-wrapper">
//         <div className="theme-body2-text">{data.title}</div>
//         <ButtonView
//           data={{
//             color: 'transparent',
//             size: 'small',
//             inneralign: 'center',
//             title: 'Veure més',
//             onClick: () => {
//               setIsContentVisible(!isContentVisible);
//             },
//           }}
//         />
//       </div>
//       <AnimateHeight duration={500} height={isContentVisible ? 'auto' : 0}>
//         <div className="iskra-accordion-content">Contingut content</div>
//       </AnimateHeight>
//     </>
//   );
// };

import React from 'react';
import { RenderBlocks } from '@plone/volto/components';
import { getPanels, accordionBlockHasValue } from './util';
import { withBlockExtensions } from '@plone/volto/helpers';
import { useLocation } from 'react-router-dom';

import AnimateHeight from 'react-animate-height';
import config from '@plone/volto/registry';
import { View as ButtonView } from '../Button/View';

const ViewComponent = (props) => {
  const { data } = props;
  const location = useLocation();
  const panels = getPanels(data.data);
  const metadata = props.metadata || props.properties;
  const [activeIndex, setActiveIndex] = React.useState([]);
  const accordionConfig = config.blocks.blocksConfig.accordion;

  const handleClick = (index) => {
    if (data.non_exclusive) {
      const newIndex =
        activeIndex.indexOf(index) === -1
          ? [...activeIndex, index]
          : activeIndex.filter((item) => item !== index);

      setActiveIndex(newIndex);
    } else {
      const newIndex =
        activeIndex.indexOf(index) === -1
          ? [index]
          : activeIndex.filter((item) => item !== index);

      setActiveIndex(newIndex);
    }
  };

  const isExclusive = (index) => {
    return activeIndex.includes(index);
  };

  React.useEffect(() => {
    return data.collapsed ? setActiveIndex([]) : setActiveIndex([0]);
  }, [data.collapsed]);

  return (
    <div className={`accordion-block iskra-accordion ${props.className}`}>
      {panels.map(([id, panel], index) => {
        return (
          accordionBlockHasValue(panel) && (
            <div
              key={id}
              className={
                data.styles
                  ? data.styles.theme
                  : accordionConfig?.defaults?.theme
              }
            >
              <div className="iskra-accordion-wrapper">
                <div className="theme-body2-text">{panel?.title}</div>
                <ButtonView
                  data={{
                    color: 'transparent',
                    size: 'small',
                    inneralign: 'center',
                    title: isExclusive(index) ? 'Veure menys' : 'Veure més',
                    onClick: () => {
                      handleClick(index);
                    },
                  }}
                />
              </div>

              <AnimateHeight
                animateOpacity
                duration={500}
                height={isExclusive(index) ? 'auto' : 0}
              >
                <div className="iskra-accordion-content">
                  <RenderBlocks
                    {...props}
                    location={location}
                    metadata={metadata}
                    content={panel}
                  />
                </div>
              </AnimateHeight>
            </div>
          )
        );
      })}
    </div>
  );
};

export const View = withBlockExtensions(ViewComponent);
