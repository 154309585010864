import config from '@plone/volto/registry';
import { BlockRenderer } from '../BlockRenderer';
import defaultImage from '../../assets/background-silder.webp';

export const View = (props) => {
  const blocksConfig =
    config.blocks.blocksConfig.__grid.blocksConfig || this.props.blocksConfig;
  return (
    <div
      className="full-width slider-background-image"
      style={{ backgroundImage: `url(${defaultImage})` }}
    >
      <div className="main-slider">
        <div className="content-main-slider">
          <h2 className="addon-test-title">
            {props.data.main_text
              ? props.data.main_text
              : 'Construïm <codi /> amb transparència'}
          </h2>
          <h3 className="addon-test-subtitle">
            {props.data.subtitle
              ? props.data.subtitle
              : 'Iskra és una empresa TIC, especialitzada en solucions tecnològiques i serveis IT per empreses i startups.'}
          </h3>
          <div className="wrapper-buttons">
            {props.data.firstButton && (
              <BlockRenderer
                {...props}
                block={props.data.firstButton.id}
                type={props.data.firstButton['@type']}
                data={props.data.firstButton}
                blocksConfig={blocksConfig}
              />
            )}
            {props.data.secondButton && (
              <BlockRenderer
                {...props}
                block={props.data.secondButton.id}
                type={props.data.secondButton['@type']}
                data={props.data.secondButton}
                blocksConfig={blocksConfig}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
