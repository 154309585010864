import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { STYLE_VARIANT_WITH_SHADOW } from './schema';
import { View as IconView } from '../Icon/View';

export const DefaultTemplate = ({ data, isEditMode }) => {
  const hasLink = data.link && data.link.length > 0;
  return (
    <div
      className={`iskra-card-wrapper ${
        data.style_variant === STYLE_VARIANT_WITH_SHADOW
          ? 'iskra-card-wrapper__with-shadow'
          : ''
      }`}
    >
      <div className="iskra-card-title theme-body1-text">
        <IconView data={data} />
        <span>{data.title}</span>
      </div>
      <div className="iskra-card-subtitle with-margin">
        <div
          className="richtext widget"
          dangerouslySetInnerHTML={{
            __html: data?.description?.data ?? '',
          }}
        />
      </div>
      {hasLink && (
        <div className="iskra-card-subtitle">
          <ConditionalLink
            to={
              data.link.length > 0 ? flattenToAppURL(data.link[0]['@id']) : ''
            }
            condition={!isEditMode}
          >
            <span> {data.linkText}</span>
          </ConditionalLink>
        </div>
      )}
    </div>
  );
};
