import { BlockDataForm } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';
import { IconTextSection } from './schema';

const messages = defineMessages({
  IconTextSectionBlock: {
    id: 'Iskra about card',
    defaultMessage: 'Iskra about card',
  },
});

export const Data = (props) => {
  const { data, block, onChangeBlock, schemaEnhancer, blocksConfig } = props;
  const intl = useIntl();
  const schema = schemaEnhancer
    ? schemaEnhancer(IconTextSection({ ...props, intl }), props)
    : IconTextSection({ ...props, intl });

  return (
    <BlockDataForm
      schema={schema}
      title={intl.formatMessage(messages.IconTextSectionBlock)}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      fieldIndex={data.index}
      onChangeBlock={props.onChangeBlock}
      block={block}
      blocksConfig={blocksConfig}
    />
  );
};
