import { Edit } from './Edit';
import { View } from './View';
import tableSVG from '@plone/volto/icons/table.svg';

export const homeSlideBlock = {
  id: '__iskra-slider-home',
  title: 'Iskra main slider',
  icon: tableSVG,
  group: 'common',
  view: View,
  edit: Edit,
  restricted: false,
  mostUsed: true,
  sidebarTab: 1,
  blockHasOwnFocusManagement: true,
  security: {
    addPermission: [],
    view: [],
  },
  allowedBlocks: ['slate', '__iskra-button'],
};
