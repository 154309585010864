import cx from 'classnames';
import React from 'react';

export const View = ({ data, className }) => {
  return (
    <div className={cx('block __button', className)}>
      <div className={cx(`align ${data?.inneralign}`)}>
        <div className="iskra-chip">{data?.text ?? ''}</div>
      </div>
    </div>
  );
};
