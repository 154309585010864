import { FormFieldWrapper, Icon } from '@plone/volto/components';
import imagePrimarySvg from '../../assets/icons/letterLIcon.svg';
import imageSecondarySvg from '../../assets/icons/letterSIcon.svg';
import imageTransparentSvg from '../../assets/icons/letterTIcon.svg';

import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';

const messages = defineMessages({
  primary: {
    id: 'Primary',
    defaultMessage: 'Primary',
  },
  secondary: {
    id: 'Secondary',
    defaultMessage: 'Secondary',
  },
  transparent: {
    id: 'Transparent',
    defaultMessage: 'Transparent',
  },
});

export const ColorWidget = (props) => {
  const intl = useIntl();

  const {
    id,
    onChange,
    actions = ['primary', 'secondary', 'transparent'],
    value,
  } = props;

  React.useEffect(() => {
    if (!props.value && props.default) {
      props.onChange(props.id, props.default);
    }
  });

  const ICON_MAP = {
    primary: imagePrimarySvg,
    secondary: imageSecondarySvg,
    transparent: imageTransparentSvg,
  };

  return (
    <FormFieldWrapper {...props} className="align-widget">
      <div className="align-buttons">
        {actions.map((action) => (
          <Button.Group key={action}>
            <Button
              icon
              basic
              aria-label={intl.formatMessage(messages[action])}
              onClick={() => onChange(id, action)}
              active={(action === 'center' && !value) || value === action}
            >
              <Icon name={ICON_MAP[action]} size="24px" />
            </Button>
          </Button.Group>
        ))}
      </div>
    </FormFieldWrapper>
  );
};
