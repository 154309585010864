import {
  cardBlock,
  buttonBlock,
  homeSlideBlock,
  SizeWidget,
  AlignWidget,
  ColorWidget,
  TreballadorItem,
  iconTextSectionBlock,
  chipBlock,
  sectionBlock,
  ContentTypeRenderBlocksBlock,
  WorkerVariantTemplate,
  ImageCarouselTemplate,
  iconBlock,
  IconWidget,
  ColorPickerWidget,
  accordionBlock,
  AccordionPanelsWidget,
} from './components';

import { defineMessages } from 'react-intl';

import installAlignTextPlugin from './editor/plugins/AlignTextPlugin';
import installTooltipPlugin from './editor/plugins/TooltipPlugin';
import { TOOLTIP } from './editor/plugins/TooltipPlugin/constants';

defineMessages({
  workerVariant: {
    id: 'Worker variant',
    defaultMessage: 'Worker variant',
  },
});

const applyConfig = (config) => {
  config.widgets.widget.inner_align = AlignWidget;
  config.widgets.widget.size = SizeWidget;
  config.widgets.widget.color = ColorWidget;
  config.widgets.widget.icon = IconWidget;
  config.widgets.widget.colorPicker = ColorPickerWidget;
  // config.widgets.widget.custom_quad_size = QuadSizeWidget;
  config.widgets.type.panels = AccordionPanelsWidget;

  config.settings.slate.toolbarButtons = [
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'separator',
    'heading-two',
    'heading-three',
    'heading-four',
    'separator',
    'clearformatting',
    'separator',
    'sub',
    'sup',
    'separator',
    'bulleted-list',
    'numbered-list',
    'blockquote',
    'styleMenu',
    'separator',
    'code',
    'link',
    TOOLTIP,
  ];
  config.settings.slate.expandedToolbarButtons = [
    ...(config.settings.slate.expandedToolbarButtons || []),
    'link',
    TOOLTIP,
  ];

  config = installAlignTextPlugin(installTooltipPlugin(config));

  config.settings.slate.styleMenu = config.settings.slate.styleMenu || {};
  config.settings.slate.styleMenu.inlineStyles = [
    ...(config.settings.slate.styleMenu?.inlineStyles || []),
    { cssClass: 'theme-title-big-text', label: 'Title big text' },
    { cssClass: 'theme-title-medium-text', label: 'Title medium text' },
    { cssClass: 'theme-title-small-text', label: 'Title small text' },
    { cssClass: 'theme-body1-text', label: 'Body1 text' },
    { cssClass: 'theme-body2-text', label: 'Body2 text' },
    { cssClass: 'theme-body3-text', label: 'Body3 text' },
    {
      cssClass: 'theme-body3-text-space-letter',
      label: 'Body3 text space letter',
    },
    { cssClass: 'theme-caption-text', label: 'Caption text' },
    { cssClass: 'theme-gray-text-color', label: 'Gray color' },
    { cssClass: 'theme-secondary-text-color', label: 'Iskra secondary color' },
    { cssClass: 'text-transform-uppercase', label: 'Uppercase' },
    { cssClass: 'text-transform-lowercase', label: 'Lowercase' },
  ];

  config.registerComponent({
    name: 'Teaser',
    component: TreballadorItem,
    dependencies: 'item_treballador',
  });

  config.blocks.blocksConfig['__iskra-card'] = cardBlock;
  config.blocks.blocksConfig['__iskra-button'] = buttonBlock;
  config.blocks.blocksConfig['__iskra-slider-home'] = homeSlideBlock;
  config.blocks.blocksConfig['__iskra-chip'] = chipBlock;
  config.blocks.blocksConfig['__iskra-group'] = sectionBlock;
  config.blocks.blocksConfig['__iskra-icon'] = iconBlock;
  config.blocks.blocksConfig['__iskra-accordion'] = accordionBlock;

  config.blocks.blocksConfig[
    '__iskra-content-type-render-blocks'
  ] = ContentTypeRenderBlocksBlock;
  config.blocks.blocksConfig[
    '__iskra-icon-text-section'
  ] = iconTextSectionBlock;

  config.blocks.blocksConfig.listing.variations = [
    ...config.blocks.blocksConfig.listing.variations,
    {
      id: 'workerVariant',
      title: 'Worker variant',
      template: WorkerVariantTemplate,
      fullobjects: true,
    },
    {
      id: 'imageVariant',
      title: 'Image variant',
      template: ImageCarouselTemplate,
      fullobjects: true,
    },
  ];

  return config;
};

export default applyConfig;
