import { FormFieldWrapper, Icon } from '@plone/volto/components';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import imageLargeSvg from '../../assets/icons/letterLIcon.svg';
import imageSmallSvg from '../../assets/icons/letterSIcon.svg';
import imageMiniSvg from '../../assets/icons/letterMIcon.svg';

const messages = defineMessages({
  large: {
    id: 'Large',
    defaultMessage: 'Large',
  },
  small: {
    id: 'Small',
    defaultMessage: 'Small',
  },
  mini: {
    id: 'Mini',
    defaultMessage: 'Mini',
  },
});

export const SizeWidget = (props) => {
  const intl = useIntl();

  const { id, onChange, actions = ['mini', 'small', 'large'], value } = props;

  React.useEffect(() => {
    if (!props.value && props.default) {
      props.onChange(props.id, props.default);
    }
  });

  const ICON_MAP = {
    large: imageLargeSvg,
    small: imageSmallSvg,
    mini: imageMiniSvg,
  };

  return (
    <FormFieldWrapper {...props} className="align-widget">
      <div className="align-buttons">
        {actions.map((action) => (
          <Button.Group key={action}>
            <Button
              icon
              basic
              aria-label={intl.formatMessage(messages[action])}
              onClick={() => onChange(id, action)}
              active={(action === 'center' && !value) || value === action}
            >
              <Icon name={ICON_MAP[action]} size="24px" />
            </Button>
          </Button.Group>
        ))}
      </div>
    </FormFieldWrapper>
  );
};
