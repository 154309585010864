/**
 * Header component.
 * @module components/theme/Header/Header
 */

import { useState, useEffect } from 'react';
import { getNavigation } from '@plone/volto/actions';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';
import config from '@plone/volto/registry';
import { Logo } from '@plone/volto/components';
import cx from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { View as ButtonView } from 'web-publica-iskra-addons/components/Button/View';
import { getBaseUrl, hasApiExpander } from '@plone/volto/helpers';
import { useDispatch } from 'react-redux';

const messages = defineMessages({
  btnHeader: {
    id: 'Book a free consultation',
    defaultMessage: 'Book a free consultation',
  },
});
/**
 * Header component class.
 * @class Header
 * @extends Component
 */

const Header = (props) => {
  const dispatch = useDispatch();
  const { pathname, token } = props;
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const { settings } = config;
    if (!hasApiExpander('navigation', getBaseUrl(pathname))) {
      dispatch(getNavigation(getBaseUrl(pathname), settings.navDepth));
    }
  }, [pathname, token, dispatch]);

  const renderLinks = () => {
    return (
      <nav className="container-links">
        {props.items.map((item) => (
          <a
            className={props.pathname === item.url ? 'selected' : ''}
            href={item.url}
            key={item.url}
          >
            {item.title}
          </a>
        ))}

        <ButtonView
          data={{
            color: 'primary',
            size: 'small',
            inneralign: 'center',
            title: props.intl.formatMessage(messages.btnHeader),
            href: [{ '@id': 'https://calendly.com/iskra-dev/20min' }],
          }}
        />
      </nav>
    );
  };

  return (
    <header className="iskra-header-section">
      <div className="iskra-menu-bg">
        <Container>
          <div className="iskra-menu">
            <div className="logo">
              <Logo />
            </div>
            <div className="tablet hidden mobile hidden">{renderLinks()}</div>
            <div className="mobile tablet only">
              <button
                className={cx('hamburger hamburger--spin', {
                  'is-active': isMobileMenuOpen,
                })}
                type="button"
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </div>
          </div>
        </Container>
      </div>
      <div
        className={cx('iskra-mobile-menu mobile tablet only', {
          'is-open': isMobileMenuOpen,
        })}
      >
        {isMobileMenuOpen && renderLinks()}
      </div>
    </header>
  );
};

// class Header2 extends Component {
//   /**
//    * Constructor
//    * @method constructor
//    * @param {Object} props Component properties
//    * @constructs Navigation
//    */
//   constructor(props) {
//     super(props);
//     this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
//     this.closeMobileMenu = this.closeMobileMenu.bind(this);
//     this.state = {
//       isMobileMenuOpen: false,
//     };
//   }

//   /**
//    * Property types.
//    * @property {Object} propTypes Property types.
//    * @static
//    */
//   static propTypes = {
//     token: PropTypes.string,
//     pathname: PropTypes.string.isRequired,
//     items: PropTypes.arrayOf(
//       PropTypes.shape({
//         title: PropTypes.string,
//         url: PropTypes.string,
//       }),
//     ).isRequired,
//     lang: PropTypes.string.isRequired,
//   };

//   /**
//    * Default properties.
//    * @property {Object} defaultProps Default properties.
//    * @static
//    */
//   static defaultProps = {
//     token: null,
//   };

//   renderLinks = () => {
//     return (
//       <div className="container-links">
//         {this.props.items.map((item) => (
//           <a
//             className={this.props.pathname === item.url ? 'selected' : ''}
//             href={item.url}
//           >
//             {item.title}
//           </a>
//         ))}

//         <Button text="Reserva una consulta gratuïta" />
//       </div>
//     );
//   };

//   /**
//    * Toggle mobile menu's open state
//    * @method toggleMobileMenu
//    * @returns {undefined}
//    */
//   toggleMobileMenu() {
//     this.setState({ isMobileMenuOpen: !this.state.isMobileMenuOpen });
//   }

//   /**
//    * Close mobile menu
//    * @method closeMobileMenu
//    * @returns {undefined}
//    */
//   closeMobileMenu() {
//     if (!this.state.isMobileMenuOpen) {
//       return;
//     }
//     this.setState({ isMobileMenuOpen: false });
//   }

//   /**
//    * Render method.
//    * @method render
//    * @returns {string} Markup for the component.
//    */
//   render() {
//     return (
//       <section className="iskra-header-section">
//         <div className="iskra-menu-bg">
//           <Container>
//             <div className="iskra-menu">
//               <div className="logo">
//                 <Logo />
//               </div>
//               <div className="tablet hidden mobile hidden">
//                 {this.renderLinks()}
//               </div>
//               <div className="mobile tablet only">
//                 <button
//                   className={cx('hamburger hamburger--spin', {
//                     'is-active': this.state.isMobileMenuOpen,
//                   })}
//                   type="button"
//                   onClick={this.toggleMobileMenu}
//                 >
//                   <span className="hamburger-box">
//                     <span className="hamburger-inner" />
//                   </span>
//                 </button>
//               </div>
//             </div>
//           </Container>
//         </div>
//         <div
//           className={cx('iskra-mobile-menu mobile only', {
//             'is-open': this.state.isMobileMenuOpen,
//           })}
//         >
//           {this.renderLinks()}
//         </div>
//       </section>
//     );
//   }
// }

export default compose(
  injectIntl,
  connect(
    (state, props) => ({
      token: state.userSession.token,
      items: state.navigation.items,
      lang: state.intl.locale,
    }),
    { getNavigation },
  ),
)(Header);
