export const MAP_STATIC_PAGES_FOOTER_BY_LANG = {
  'kit-digital': {
    ca: 'kit-digital',
    es: 'kit-digital',
    en: 'kit-digital',
  },
  services: {
    ca: 'serveis',
    es: 'servicios',
    en: 'services',
  },
  'about-us': {
    ca: 'sobre-nosaltres',
    es: 'sobre-nosotros',
    en: 'about-us',
  },
  'job-offers': {
    ca: 'ofertes-de-treball',
    es: 'ofertas-de-trabajo',
    en: 'job-offers',
  },
  'cookies-policy': {
    ca: 'politica-de-cookies',
    es: 'politica-de-cookies',
    en: 'cookies-policy',
  },
  legal: {
    ca: 'legal',
    es: 'legal',
    en: 'legal',
  },
  'privacy-police': {
    ca: 'politica-de-privacitat',
    es: 'politica-de-privacidad',
    en: 'privacy-police',
  },
};
