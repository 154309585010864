import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Target: {
    id: 'Target',
    defaultMessage: 'Target',
  },
});

export const ContentTypeRenderBlocksSchema = ({ intl }) => {
  return {
    title: 'Iskra Main slider',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['target'],
      },
    ],

    properties: {
      target: {
        title: intl.formatMessage(messages.Target),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description', '@type'],
        allowExternals: true,
      },
    },

    required: [],
  };
};
