import { Edit } from './Edit';
import { View } from './View';
import circleMenuSVG from '@plone/volto/icons/circle-menu.svg';

export const chipBlock = {
  id: '__iskra-chip',
  title: 'Iskra chip',
  icon: circleMenuSVG,
  group: 'common',
  view: View,
  edit: Edit,
  restricted: false,
  mostUsed: true,
  sidebarTab: 1,
  security: {
    addPermission: [],
    view: [],
  },
};
