import React from 'react';
import { FormFieldWrapper, Icon } from '@plone/volto/components';
import { Button } from 'semantic-ui-react';
import loadable from '@loadable/component';
import clearSVG from '@plone/volto/icons/clear.svg';

const ReactColor = loadable.lib(() => import('react-color'));

export const ColorPickerWidget = (props) => {
  const { id, value, onChange } = props;
  const [showPicker, setShowPicker] = React.useState(false);

  return (
    <FormFieldWrapper
      {...props}
      draggable={false}
      className="simple-color-picker-widget"
    >
      <div className="wrapper">
        <Button.Group>
          <Button
            color={value}
            style={{ backgroundColor: value }}
            onClick={() => setShowPicker(true)}
            size="huge"
            title="Pick color"
          >
            {''}
          </Button>
          <Button
            compact
            style={{ paddingLeft: '8px', paddingRight: '0px' }}
            onClick={() => onChange(id, null)}
          >
            <Icon name={clearSVG} size="18px" color="red" />
          </Button>
        </Button.Group>

        {showPicker && (
          <ReactColor>
            {({ TwitterPicker }) => {
              return (
                <TwitterPicker
                  width="220px"
                  triangle="top"
                  className="color-picker"
                  colors={[
                    '#fcb713',
                    '#0070F3',
                    '#FAFAFA',
                    '#EAEAEA',
                    '#999',
                    '#888',
                    '#666',
                    '#444',
                    '#333',
                    '#111',
                  ]}
                  color={value || '#000'}
                  onChangeComplete={(value) => {
                    setShowPicker(false);
                    onChange(id, value.hex);
                  }}
                />
              );
            }}
          </ReactColor>
        )}
      </div>
    </FormFieldWrapper>
  );
};
