import { Icon, SelectWidget } from '@plone/volto/components';
import { useMemo, useEffect } from 'react';
import { MAP_ICONS } from '../Icon/configIcons';

export const IconWidget = (props) => {
  const { id, onChange, value } = props;

  const options = useMemo(() => {
    if (MAP_ICONS) {
      return Object.keys(MAP_ICONS).map((key) => [
        key,
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <span>{key}</span>
          <Icon name={MAP_ICONS[key]} size="24px" />
        </div>,
      ]);
    }
    return [];
  }, []);

  useEffect(() => {
    if (!props.value && props.default) {
      props.onChange(props.id, props.default);
    }
  });

  return (
    <SelectWidget
      title={props.title}
      getVocabulary={() => {}}
      getVocabularyTokenTitle={() => {}}
      choices={options}
      value={value}
      onChange={(field, value) => onChange(id, value)}
      noValueOption={false}
    />
  );
};
