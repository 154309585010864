import React from 'react';
import { useDispatch } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { getContent } from '@plone/volto/actions';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function withQueryContentResults(WrappedComponent) {
  function WithQueryContentResults(props) {
    const { data = {}, id = data.block, path } = props;
    const dispatch = useDispatch();

    useDeepCompareEffect(() => {
      dispatch(getContent(data.target[0]['@id'], null, id));
    }, [id, path, dispatch, data.target]);

    return <WrappedComponent {...props} />;
  }

  WithQueryContentResults.displayName = `WithQueryContentResults(${getDisplayName(
    WrappedComponent,
  )})`;

  return hoistNonReactStatics(WithQueryContentResults, WrappedComponent);
}
