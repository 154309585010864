/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { injectIntl } from 'react-intl';
import linkedinSvg from '../../../../assets/icons/linkedin.svg';
import twitterSvg from '../../../../assets/icons/twitter.svg';
import githubSvg from '../../../../assets/icons/github.svg';
import { Icon, Logo, LanguageSelector } from '@plone/volto/components';
import { MAP_STATIC_PAGES_FOOTER_BY_LANG } from '../../../../helpers/mapStaticPagesFooterBYLang';
import { footerMessages as messages } from '../../../../messages';

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  return (
    <footer>
      <section className="iskra-footer-section">
        <div className="iskra-footer-navigation-section">
          <div>
            <div className="logo mb-16">
              <Logo />
            </div>
            <LanguageSelector />
          </div>

          <div className="iskra-footer-navigation-section-column">
            <div>
              <p className="theme-body2-text">
                {intl.formatMessage(messages.services)}
              </p>
              <nav>
                <ul>
                  <li>
                    <a
                      className="theme-body3-text"
                      href={`/${intl.locale}/${
                        MAP_STATIC_PAGES_FOOTER_BY_LANG['services'][intl.locale]
                      }`}
                    >
                      {intl.formatMessage(messages.services)}
                    </a>
                  </li>
                  <li>
                    <a
                      className="theme-body3-text"
                      href={`/${intl.locale}/${
                        MAP_STATIC_PAGES_FOOTER_BY_LANG['kit-digital'][
                          intl.locale
                        ]
                      }`}
                    >
                      {intl.formatMessage(messages.kitDigital)}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="iskra-footer-navigation-section-column">
            <div>
              <p className="theme-body2-text">
                {intl.formatMessage(messages.explore)}
              </p>
              <nav>
                <ul>
                  <li>
                    <a
                      className="theme-body3-text"
                      href={`/${intl.locale}/${
                        MAP_STATIC_PAGES_FOOTER_BY_LANG['about-us'][intl.locale]
                      }`}
                    >
                      {intl.formatMessage(messages.aboutUs)}
                    </a>
                  </li>
                  <li>
                    <a
                      className="theme-body3-text"
                      href={`/${intl.locale}/${
                        MAP_STATIC_PAGES_FOOTER_BY_LANG['job-offers'][
                          intl.locale
                        ]
                      }`}
                    >
                      {intl.formatMessage(messages.jobOffers)}
                    </a>
                  </li>
                  <li>
                    <a
                      className="theme-body3-text"
                      href={`/${intl.locale}/contact-form`}
                    >
                      {intl.formatMessage(messages.contact)}
                    </a>
                  </li>
                  {/* <li>
                    <a
                      className="theme-body3-text"
                      href={`/${intl.locale}/faqs`}
                    >
                      FAQs
                    </a>
                  </li> */}
                </ul>
              </nav>
            </div>
          </div>
          <div className="iskra-footer-navigation-section-column">
            <div>
              <p className="theme-body2-text">
                {intl.formatMessage(messages.legal)}
              </p>
              <nav>
                <ul>
                  <li>
                    <a
                      className="theme-body3-text"
                      href={`/${intl.locale}/${
                        MAP_STATIC_PAGES_FOOTER_BY_LANG['cookies-policy'][
                          intl.locale
                        ]
                      }`}
                    >
                      {intl.formatMessage(messages.cookiesPolicy)}
                    </a>
                  </li>
                  <li>
                    <a
                      className="theme-body3-text"
                      href={`/${intl.locale}/${
                        MAP_STATIC_PAGES_FOOTER_BY_LANG['legal'][intl.locale]
                      }`}
                    >
                      {intl.formatMessage(messages.legalNotice)}
                    </a>
                  </li>
                  <li>
                    <a
                      className="theme-body3-text"
                      href={`/${intl.locale}/${
                        MAP_STATIC_PAGES_FOOTER_BY_LANG['privacy-police'][
                          intl.locale
                        ]
                      }`}
                    >
                      {intl.formatMessage(messages.privacyPolice)}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="iskra-footer-section">
        <div className="flex justify-content-space-between align-items-center mobile-flex-row">
          <div>
            <div className="theme-body3-text">
              {intl.formatMessage(messages.newsletter)}
            </div>
            <div className="theme-body3-text theme-gray-text-color">
              {intl.formatMessage(messages.subscribeMessage)}
            </div>
          </div>
          <div className="flex align-items-center" style={{ gap: '10px' }}>
            <input placeholder="youremail@iskra.cat" />
            <ButtonView
              data={{
                color: 'primary',
                size: 'small',
                inneralign: 'center',
                title: intl.formatMessage(messages.subscribe),c
              }}
            />
          </div>
        </div>
      </section> */}
      <section className="iskra-footer-section">
        <div className="flex justify-content-space-between align-items-center mobile-flex-row">
          <div className="theme-body3-text theme-gray-text-color">
            {intl.formatMessage(messages.copyright)}
          </div>
          <div className="flex" style={{ gap: '16px' }}>
            <a
              className="theme-gray-text-color"
              href="https://www.linkedin.com/company/2229387"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name={linkedinSvg} size="32px" />
            </a>
            <a
              className="theme-gray-text-color"
              href="https://twitter.com/iskraTIC"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name={twitterSvg} size="32px" />
            </a>
            <a
              className="theme-gray-text-color"
              href="https://github.com/iskracat"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name={githubSvg} size="32px" />
            </a>
          </div>
        </div>
      </section>
    </footer>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
