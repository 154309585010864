import { defineMessages } from 'react-intl';

export const headerMessages = defineMessages({
  btnHeader: {
    id: 'Book a free consultation',
    defaultMessage: 'Book a free consultation',
  },
});

export const footerMessages = defineMessages({
  services: {
    id: 'Services',
    defaultMessage: 'Services',
  },
  kitDigital: {
    id: 'kitDigital',
    defaultMessage: 'Kit Digital',
  },
  aboutUs: {
    id: 'aboutUs',
    defaultMessage: 'About us',
  },
  explore: {
    id: 'explore',
    defaultMessage: 'Explore',
  },
  jobOffers: {
    id: 'jobOffers',
    defaultMessage: 'Job Offers',
  },
  contact: {
    id: 'contact',
    defaultMessage: 'Contact',
  },
  legal: {
    id: 'legal',
    defaultMessage: 'Legal',
  },
  cookiesPolicy: {
    id: 'cookiesPolicy',
    defaultMessage: 'Cookies policy',
  },
  legalNotice: {
    id: 'legalNotice',
    defaultMessage: 'Legal notice',
  },
  privacyPolice: {
    id: 'privacyPolice',
    defaultMessage: 'Privace policy',
  },
  subscribe: {
    id: 'Subscribe',
    defaultMessage: 'Subscribe',
  },
  subscribeMessage: {
    id: 'subscribeMessage',
    defaultMessage:
      "Subscribe and you won't miss our latest activities, posts and news.",
  },
  newsletter: {
    id: 'Newsletter',
    defaultMessage: 'Newsletter',
  },
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright © 2023 Iskra. All rights reserved.',
  },
});

export const notFoundMessages = defineMessages({
  notFoundTitle: {
    id: 'This page does not seem to exist…',
    defaultMessage: 'This page does not seem to exist…',
  },
  notFoundDescription: {
    id: 'Not found page description text',
    defaultMessage:
      'It is possible that the link you have followed is not correct or that the page has been deleted.',
  },
});

export const contactFormMessages = defineMessages({
  send: {
    id: 'Send',
    defaultMessage: 'Send',
  },
  sending: {
    id: 'Sending',
    defaultMessage: 'Sending',
  },
  name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  from: {
    id: 'From',
    defaultMessage: 'From',
  },
  message: {
    id: 'Message',
    defaultMessage: 'Message',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  messageSent: {
    id: 'Email sent',
    defaultMessage: 'Email sent',
  },
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  howCanWeHelp: {
    id: 'How can we help?',
    defaultMessage: 'How can we help?',
  },
  howCanWeHelpSubtitle: {
    id: 'howCanWeHelpSubtitle',
    defaultMessage:
      'If you have any questions about your project, services, prices, training or anything else related to Iskra, we are here to help you.',
  },
  bookAMeeting: {
    id: 'Book a meeting',
    defaultMessage:
      "Book a free consultation and we'll start talking about your project.",
  },
  bookingNow: {
    id: 'Booking now',
    defaultMessage: 'Booking now',
  },
  requiredField: {
    id: 'Required field',
    defaultMessage: 'Required field',
  },
  incorrectFormat: {
    id: 'Incorrect format',
    defaultMessage: 'Incorrect format',
  },
});
