import { MarkElementButton } from '@plone/volto-slate/editor/ui';
import textAlignCenterIcon from '../../../assets/icons/text_align_center.svg';
import textAlignLeftIcon from '../../../assets/icons/text_align_left.svg';
import textAlignRightIcon from '../../../assets/icons/text_align_right.svg';
// import { cloneElement, Children } from 'react';

// export const TextAlignCenterElement = ({ children }) => {
//   return Children.map(children, (el) => {
//     return cloneElement(el, { style: { textAlign: 'center' } });
//   });
// };
// export const TextAlignRightElement = ({ children }) => {
//   return Children.map(children, (el) => {
//     return cloneElement(el, { style: { textAlign: 'right' } });
//   });
// };
// export const TextAlignLeftElement = ({ children }) => {
//   return Children.map(children, (el) => {
//     return cloneElement(el, { style: { textAlign: 'left' } });
//   });
// };

export const TextAlignCenterElement = ({ children }) => {
  return <div style={{ textAlign: 'center' }}>{children}</div>;
};
export const TextAlignRightElement = ({ children }) => {
  return <div style={{ textAlign: 'right' }}>{children}</div>;
};
export const TextAlignLeftElement = ({ children }) => {
  return <div style={{ textAlign: 'left' }}>{children}</div>;
};

export default function install(config) {
  const { slate } = config.settings;

  slate.buttons['text-align-center'] = (props) => (
    <MarkElementButton
      format="text-align-center"
      icon={textAlignCenterIcon}
      title="Text align center"
      {...props}
    />
  );
  slate.buttons['text-align-right'] = (props) => (
    <MarkElementButton
      format="text-align-right"
      icon={textAlignRightIcon}
      title="Text align right"
      {...props}
    />
  );
  slate.buttons['text-align-left'] = (props) => (
    <MarkElementButton
      format="text-align-left"
      icon={textAlignLeftIcon}
      title="Text align left"
      {...props}
    />
  );
  slate.elements['text-align-center'] = TextAlignCenterElement;
  slate.elements['text-align-right'] = TextAlignRightElement;
  slate.elements['text-align-left'] = TextAlignLeftElement;

  slate.toolbarButtons.push('text-align-center');
  slate.expandedToolbarButtons.push('text-align-center');
  slate.toolbarButtons.push('text-align-right');
  slate.expandedToolbarButtons.push('text-align-right');
  slate.toolbarButtons.push('text-align-left');
  slate.expandedToolbarButtons.push('text-align-left');

  return config;
}
