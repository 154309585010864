import { defineMessages } from 'react-intl';
const messages = defineMessages({
  IskraCardBlock: {
    id: 'Iskra card',
    defaultMessage: 'Iskra card',
  },
  CardTitle: {
    id: 'CardTitle',
    defaultMessage: 'Title',
  },
  CardDescription: {
    id: 'CardDescription',
    defaultMessage: 'Description',
  },
  CardLink: {
    id: 'CardLink',
    defaultMessage: 'Link',
  },
  CardLinkText: {
    id: 'CardLinkText',
    defaultMessage: 'Link Text',
  },
  Icon: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
  CardStyleVariant: {
    id: 'CardStyleVariant',
    defaultMessage: 'Style variant',
  },
  CardStyleVariantWithShadowOption: {
    id: 'CardStyleVariantWithShadowOption',
    defaultMessage: 'With shadow',
  },
  CardStyleVariantWithoutShadowOption: {
    id: 'CardStyleVariantWithoutShadowOption',
    defaultMessage: 'Without shadow',
  },
  CardCentertext: {
    id: 'CardCentertext',
    defaultMessage: 'Center text',
  },
  Yes: {
    id: 'Yes',
    defaultMessage: 'Yes',
  },
  No: {
    id: 'No',
    defaultMessage: 'No',
  },
  BackgroundColor: {
    id: 'Bakground Color',
    defaultMessage: 'Bakground Color',
  },
  BorderColor: {
    id: 'Border Color',
    defaultMessage: 'Border Color',
  },
  Color: {
    id: 'Color',
    defaultMessage: 'Color',
  },
  IconShape: {
    id: 'Icon Shape',
    defaultMessage: 'Icon Shape',
  },
  IconSize: {
    id: 'Icon Size',
    defaultMessage: 'Icon Size',
  },
  IconShapeRounded: {
    id: 'Rounded',
    defaultMessage: 'Rounded',
  },
  IconShapeCirlce: {
    id: 'Circle',
    defaultMessage: 'Circle',
  },
  IconShapeNone: {
    id: 'None',
    defaultMessage: 'None',
  },
});

export const STYLE_VARIANT_WITH_SHADOW = 'with-shadow';
export const STYLE_VARIANT_WITHOUT_SHADOW = 'without-shadow';
export const ICON_BORDER_TYPE = {
  ROUNDED: 'rounded',
  CIRCLE: 'circle',
  NONE: 'none',
};

export const IskraCardSchema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.IskraCardBlock),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'title',
          'description',
          'link',
          'linkText',
          'style_variant',
          'center_text',
        ],
      },
      {
        id: 'icon',
        title: 'Icon',
        fields: [
          'icon',
          'backgroundColor',
          'borderColor',
          'iconColor',
          'iconShape',
          'iconSize',
        ],
      },
    ],

    properties: {
      title: {
        title: props.intl.formatMessage(messages.CardTitle),
      },
      description: {
        title: props.intl.formatMessage(messages.CardDescription),
        type: 'string',
        widget: 'richtext',
      },
      link: {
        title: props.intl.formatMessage(messages.CardLink),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description', 'hasPreviewImage'],
        allowExternals: true,
      },
      linkText: {
        title: props.intl.formatMessage(messages.CardLinkText),
      },
      icon: {
        title: props.intl.formatMessage(messages.Icon),
        widget: 'icon',
        default: 'block',
      },
      center_text: {
        title: props.intl.formatMessage(messages.CardCentertext),
        type: 'string',
        factory: 'Choice',
        default: true,
        choices: [
          [true, props.intl.formatMessage(messages.Yes)],
          [false, props.intl.formatMessage(messages.No)],
        ],
      },
      style_variant: {
        title: props.intl.formatMessage(messages.CardStyleVariant),
        type: 'string',
        factory: 'Choice',
        default: STYLE_VARIANT_WITH_SHADOW,
        choices: [
          [
            STYLE_VARIANT_WITH_SHADOW,
            props.intl.formatMessage(messages.CardStyleVariantWithShadowOption),
          ],
          [
            STYLE_VARIANT_WITHOUT_SHADOW,
            props.intl.formatMessage(
              messages.CardStyleVariantWithoutShadowOption,
            ),
          ],
        ],
      },
      backgroundColor: {
        title: props.intl.formatMessage(messages.BackgroundColor),
        widget: 'colorPicker',
      },
      borderColor: {
        title: props.intl.formatMessage(messages.BorderColor),
        widget: 'colorPicker',
      },
      iconColor: {
        title: props.intl.formatMessage(messages.Color),
        widget: 'colorPicker',
      },
      iconShape: {
        title: props.intl.formatMessage(messages.IconShape),
        type: 'string',
        factory: 'Choice',
        default: ICON_BORDER_TYPE.ROUNDED,
        choices: [
          [
            ICON_BORDER_TYPE.ROUNDED,
            props.intl.formatMessage(messages.IconShapeRounded),
          ],
          [
            ICON_BORDER_TYPE.CIRCLE,
            props.intl.formatMessage(messages.IconShapeCirlce),
          ],
          [
            ICON_BORDER_TYPE.NONE,
            props.intl.formatMessage(messages.IconShapeNone),
          ],
        ],
      },
      iconSize: {
        title: props.intl.formatMessage(messages.IconSize),
        widget: 'size',
        default: 'small',
      },
    },
    required: ['title', 'description', 'link', 'linkText', 'icon'],
  };
};
