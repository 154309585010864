import React from 'react';
import cx from 'classnames';
import { ConditionalLink } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  ButtonText: {
    id: 'Button text',
    defaultMessage: 'Button text',
  },
});

export const View = ({ data, isEditMode, className }) => {
  const [hasLink, setHasLink] = React.useState(false);
  const intl = useIntl();
  React.useEffect(() => {
    if (data.href) {
      if (data.href && data.href.length > 0) {
        setHasLink(true);
      }
      if (data.href.length === 0) {
        setHasLink(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.href]);

  let link = hasLink ? (
    data.href.length > 0 && (
      <ConditionalLink
        to={data.href[0]?.['@id']}
        condition={!isEditMode}
        openLinkInNewTab={data.openLinkInNewTab}
      >
        <button
          className={`iskra-button iskra-button-${data?.color} iskra-button-${data?.size}`}
        >
          {data.title || intl.formatMessage(messages.ButtonText)}
        </button>
      </ConditionalLink>
    )
  ) : (
    <button
      type={data.type ? data.type : 'button'}
      disabled={data.disabled}
      className={`iskra-button iskra-button-${data?.color} iskra-button-${data?.size} noLink`}
      onClick={() => {
        if (data.onClick) {
          data.onClick();
        }
      }}
    >
      {data.title || intl.formatMessage(messages.ButtonText)}
    </button>
  );

  return (
    <div className={`${className} __button`}>
      <div className={cx(`align ${data?.inneralign}`)}>{link}</div>
    </div>
  );
};
