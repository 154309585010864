const IframeSchema = {
  title: 'Iskra Main slider',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['main_text', 'subtitle'],
    },
  ],

  properties: {
    main_text: {
      title: 'Main text',
    },
    subtitle: {
      title: 'Subtitle',
      widget: 'text',
    },
  },

  required: ['main_text', 'subtitle'],
};

export default IframeSchema;
