import { useSelector } from 'react-redux';
import { withQueryContentResults } from './withQueryContentResults';
import { RenderBlocks } from '@plone/volto/components';

const ViewContent = (props) => {
  const content = useSelector((state) => state.content.subrequests);
  const objectContent = content[props.id];
  return <RenderBlocks {...props} content={objectContent?.data || {}} />;
};

export const View = withQueryContentResults(ViewContent);
