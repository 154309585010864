import { defineMessages } from 'react-intl';
const messages = defineMessages({
  IconTextSectionBlock: {
    id: 'Iskra about card',
    defaultMessage: 'Iskra about card',
  },
  Text: {
    id: 'Text',
    defaultMessage: 'Text',
  },
  Icon: {
    id: 'Icon',
    defaultMessage: 'Icon svg',
  },
  BackgroundColor: {
    id: 'Bakground Color',
    defaultMessage: 'Bakground Color',
  },
  BorderColor: {
    id: 'Border Color',
    defaultMessage: 'Border Color',
  },
  Color: {
    id: 'Color',
    defaultMessage: 'Color',
  },
  IconShape: {
    id: 'Icon Shape',
    defaultMessage: 'Icon Shape',
  },
  IconSize: {
    id: 'Icon Size',
    defaultMessage: 'Icon Size',
  },
  IconShapeRounded: {
    id: 'Rounded',
    defaultMessage: 'Rounded',
  },
  IconShapeCirlce: {
    id: 'Circle',
    defaultMessage: 'Circle',
  },
  IconShapeNone: {
    id: 'None',
    defaultMessage: 'None',
  },
});

export const ICON_BORDER_TYPE = {
  ROUNDED: 'rounded',
  CIRCLE: 'circle',
  NONE: 'none',
};
export const IconTextSection = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.IconTextSectionBlock),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['text'],
      },
      {
        id: 'icon',
        title: 'Icon',
        fields: [
          'icon',
          'backgroundColor',
          'borderColor',
          'iconColor',
          'iconShape',
          'iconSize',
        ],
      },
    ],
    properties: {
      text: {
        title: props.intl.formatMessage(messages.Text),
        type: 'string',
        widget: 'richtext',
      },

      icon: {
        title: props.intl.formatMessage(messages.Icon),
        widget: 'icon',
        default: 'block',
      },
      backgroundColor: {
        title: props.intl.formatMessage(messages.BackgroundColor),
        widget: 'colorPicker',
      },
      borderColor: {
        title: props.intl.formatMessage(messages.BorderColor),
        widget: 'colorPicker',
      },
      iconColor: {
        title: props.intl.formatMessage(messages.Color),
        widget: 'colorPicker',
      },
      iconShape: {
        title: props.intl.formatMessage(messages.IconShape),
        type: 'string',
        factory: 'Choice',
        default: ICON_BORDER_TYPE.ROUNDED,
        choices: [
          [
            ICON_BORDER_TYPE.ROUNDED,
            props.intl.formatMessage(messages.IconShapeRounded),
          ],
          [
            ICON_BORDER_TYPE.CIRCLE,
            props.intl.formatMessage(messages.IconShapeCirlce),
          ],
          [
            ICON_BORDER_TYPE.NONE,
            props.intl.formatMessage(messages.IconShapeNone),
          ],
        ],
      },
      iconSize: {
        title: props.intl.formatMessage(messages.IconSize),
        widget: 'size',
        default: 'small',
      },
    },
    required: ['text', 'icon'],
  };
};
