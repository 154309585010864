import Slider from 'react-slick';

import { flattenToAppURL } from '@plone/volto/helpers';
import cx from 'classnames';

export const ImageCarouselTemplate = ({ items, isEditMode }) => {
  var settings = {
    dotsClass: 'carousel-dots',
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: !isEditMode,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <div className={cx({ 'ui container': isEditMode })}>
      <Slider {...settings}>
        {items.map((item) => (
          <img
            key={item['@id']}
            src={flattenToAppURL(`${item['@id']}/@@images/image/teaser`)}
            alt=""
            loading="lazy"
            width="150px"
            height="100px"
            className="image-carousel"
          />
        ))}
      </Slider>
    </div>
  );
};
