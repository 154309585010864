import React from 'react';
import PropTypes from 'prop-types';
import { Component, Icon } from '@plone/volto/components';
import linkedinSvg from '../../../../../web-publica-iskra-theme/src/assets/icons/linkedin.svg';
import twitterSvg from '../../../../../web-publica-iskra-theme/src/assets/icons/twitter.svg';
import githubSvg from '../../../../../web-publica-iskra-theme/src/assets/icons/github.svg';

export const WorkerVariantTemplate = ({ items }) => {
  return (
    <div className="worker-variant-listing">
      {items.map((item) => (
        <div className="worker-variant-item" key={item['@id']}>
          <Component
            componentName="PreviewImage"
            item={item}
            src={item?.avatar?.scales?.preview?.download}
            alt=""
          />
          <div
            className="flex align-items-center"
            style={{ flexDirection: 'column' }}
          >
            <h3 className="theme-body1-text text-align-center">
              {item.title ? item.title : item.id}
            </h3>
            <p className="theme-body3-text text-align-center">
              {item.position}
            </p>
            <div className="flex" style={{ gap: '16px' }}>
              {item.linkedin && (
                <a
                  className="theme-gray-text-color"
                  href={`https://www.linkedin.com/in/${item.linkedin}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name={linkedinSvg} size="20px" />
                </a>
              )}
              {item.twitter && (
                <a
                  className="theme-gray-text-color"
                  href={`https://twitter.com/${item.twitter}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name={twitterSvg} size="20px" />
                </a>
              )}
              {item.github && (
                <a
                  className="theme-gray-text-color"
                  href={`https://github.com/${item.github}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name={githubSvg} size="20px" />
                </a>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

WorkerVariantTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
