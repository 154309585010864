import codeSVG from '@plone/volto/icons/row.svg';

import { Edit } from './Edit';
import { View } from './View';

// based on https://github.com/eea/volto-group-block
export const sectionBlock = {
  id: '__iskra-group',
  title: 'Section (Group Iskra)',
  icon: codeSVG,
  group: 'common',
  view: View,
  edit: Edit,
  restricted: false,
  mostUsed: false,
  blockHasOwnFocusManagement: true,
  sidebarTab: 1,
  security: {
    addPermission: [],
    view: [],
  },
};
