import React from 'react';
import AnimateHeight from 'react-animate-height';
import { Input } from 'semantic-ui-react';
import config from '@plone/volto/registry';
import { View as ButtonView } from '../Button/View';

export const AccordionEdit = (props) => {
  const {
    children,
    handleTitleChange,
    handleTitleClick,
    uid,
    panel,
    data,
    index,
  } = props;
  const [activeIndex, setActiveIndex] = React.useState([0]);
  const accordionConfig = config.blocks.blocksConfig.accordion;

  const handleClick = (index) => {
    if (data.non_exclusive) {
      const newIndex =
        activeIndex.indexOf(index) === -1
          ? [...activeIndex, index]
          : activeIndex.filter((item) => item !== index);

      setActiveIndex(newIndex);
    } else {
      const newIndex =
        activeIndex.indexOf(index) === -1
          ? [index]
          : activeIndex.filter((item) => item !== index);

      setActiveIndex(newIndex);
    }
  };

  const isExclusive = (index) => {
    return activeIndex.includes(index);
  };

  React.useEffect(() => {
    return data.collapsed ? setActiveIndex([]) : setActiveIndex([0]);
  }, [data.collapsed]);

  return (
    <div
      className={
        data.styles ? data.styles.theme : accordionConfig?.defaults?.theme
      }
    >
      <React.Fragment>
        <div className="iskra-accordion-wrapper">
          {!data.readOnlyTitles ? (
            <Input
              fluid
              className="iskra-accordion-input-title"
              transparent
              placeholder="Enter Title"
              value={panel?.title || ''}
              onClick={(e) => {
                handleTitleClick();
                e.stopPropagation();
              }}
              onChange={(e) => handleTitleChange(e, [uid, panel])}
            />
          ) : (
            <div className="theme-body2-text">{panel?.title}</div>
          )}

          <ButtonView
            data={{
              color: 'transparent',
              size: 'small',
              inneralign: 'center',
              title: isExclusive(index) ? 'Veure menys' : 'Veure més',
              onClick: () => {
                handleClick(index);
              },
            }}
          />
        </div>
        <AnimateHeight
          animateOpacity
          duration={500}
          height={isExclusive(index) ? 'auto' : 0}
        >
          <div className="iskra-accordion-content">{children}</div>
        </AnimateHeight>
      </React.Fragment>
    </div>
  );
};
