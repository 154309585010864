import accountTreeSvg from '../../assets/icons/material/account_tree.svg';
import addTaskSvg from '../../assets/icons/material/add_task.svg';
import blockSvg from '../../assets/icons/material/block.svg';
import chatSvg from '../../assets/icons/material/chat.svg';
import checkSvg from '../../assets/icons/material/check.svg';
import cloudSvg from '../../assets/icons/material/cloud.svg';
import codeSvg from '../../assets/icons/material/code.svg';
import devicesSvg from '../../assets/icons/material/devices.svg';
import domainSvg from '../../assets/icons/material/domain.svg';
import euroSvg from '../../assets/icons/material/euro.svg';
import favouriteSvg from '../../assets/icons/material/favourite.svg';
import folderSvg from '../../assets/icons/material/folder.svg';
import groupSvg from '../../assets/icons/material/group.svg';
import helpSvg from '../../assets/icons/material/help.svg';
import howToRegSvg from '../../assets/icons/material/how_to_reg.svg';
import languageSvg from '../../assets/icons/material/language.svg';
import reduceCapacitySvg from '../../assets/icons/material/reduce_capacity.svg';
import rotateRightSvg from '../../assets/icons/material/rotate_right.svg';
import scheduleSvg from '../../assets/icons/material/schedule.svg';
import securitySvg from '../../assets/icons/material/security.svg';
import settingsSvg from '../../assets/icons/material/settings.svg';
import shoppingCartSvg from '../../assets/icons/material/shopping_cart.svg';
import smileyFaceSvg from '../../assets/icons/material/smiley_face.svg';
import speedSvg from '../../assets/icons/material/speed.svg';
import tendingUpSvg from '../../assets/icons/material/tending_up.svg';
import thumbUp from '../../assets/icons/material/thumb_up.svg';
import visibilitySvg from '../../assets/icons/material/visibility.svg';
import volunteerActivismSvg from '../../assets/icons/material/volunteer_activism.svg';

export const MAP_ICONS = {
  account_tree: accountTreeSvg,
  add_task: addTaskSvg,
  block: blockSvg,
  chat: chatSvg,
  check: checkSvg,
  cloud: cloudSvg,
  code: codeSvg,
  devices: devicesSvg,
  domain: domainSvg,
  euro: euroSvg,
  favourite: favouriteSvg,
  folder: folderSvg,
  group: groupSvg,
  help: helpSvg,
  how_to_reg: howToRegSvg,
  language: languageSvg,
  reduce_capacity: reduceCapacitySvg,
  rotate_right: rotateRightSvg,
  schedule: scheduleSvg,
  security: securitySvg,
  settings: settingsSvg,
  shopping_cart: shoppingCartSvg,
  smiley_face: smileyFaceSvg,
  speed: speedSvg,
  tending_up: tendingUpSvg,
  thumb_up: thumbUp,
  visibility: visibilitySvg,
  volunteer_activism: volunteerActivismSvg,
};
