import { makeInlineElementPlugin } from '@plone/volto-slate/elementEditor';
import tooltipSVG from '@plone/volto/icons/help.svg';
import { defineMessages } from 'react-intl';
import TooltipElement from './TooltipElement';
import { TOOLTIP } from './constants';
import { withTooltip } from './extensions';
import { TooltipEditorSchema } from './schema';

const messages = defineMessages({
  edit: {
    id: 'Edit tooltip',
    defaultMessage: 'Edit tooltip',
  },
  delete: {
    id: 'Remove tooltip',
    defaultMessage: 'Remove tooltip',
  },
});

export default function installTooltipPlugin(config) {
  const opts = {
    title: 'Tooltip',
    pluginId: TOOLTIP,
    elementType: TOOLTIP,
    element: TooltipElement,
    isInlineElement: true,
    editSchema: TooltipEditorSchema,
    extensions: [withTooltip],
    hasValue: (formData) => !!formData,
    toolbarButtonIcon: tooltipSVG,
    messages,
  };
  const [installEditor] = makeInlineElementPlugin(opts);
  config = installEditor(config);
  return config;
}
