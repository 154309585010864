import { BlockDataForm } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';
import { ContentTypeRenderBlocksSchema } from './schema';

const messages = defineMessages({
  IskraCardBlock: {
    id: 'Iskra card',
    defaultMessage: 'Iskra card',
  },
});

export const Data = (props) => {
  const { data, block, onChangeBlock, schemaEnhancer } = props;
  const intl = useIntl();
  const schema = schemaEnhancer
    ? schemaEnhancer(ContentTypeRenderBlocksSchema({ ...props, intl }), props)
    : ContentTypeRenderBlocksSchema({ ...props, intl });

  return (
    <BlockDataForm
      schema={schema}
      title={intl.formatMessage(messages.IskraCardBlock)}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      fieldIndex={data.index}
      block={block}
    />
  );
};
