import config from '@plone/volto/registry';
import { withBlockExtensions } from '@plone/volto/helpers';

const ViewContent = (props) => {
  const { data, isEditMode, variation } = props;
  const variations =
    config.blocks?.blocksConfig['__iskra-card']?.variations || [];
  const defaultVariation = variations.filter((item) => item.isDefault)?.[0];
  const CardBodyTemplate =
    variation?.template ?? defaultVariation?.template ?? null;

  return <CardBodyTemplate data={data} isEditMode={isEditMode} />;
};

export const View = withBlockExtensions(ViewContent);
