import { View } from './View';
import { Edit } from './Edit';
import { getCustomBlockAsyncData } from './getAsyncData';
import tableSVG from '@plone/volto/icons/table.svg';

export const ContentTypeRenderBlocksBlock = {
  id: '__iskra-content-type-render-blocks',
  title: 'Content type render blocks',
  icon: tableSVG,
  group: 'common',
  view: View,
  edit: Edit,
  restricted: false,
  getAsyncData: getCustomBlockAsyncData,
};
