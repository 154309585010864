import { defineMessages } from 'react-intl';
const messages = defineMessages({
  ChipBlock: {
    id: 'Chip Block',
    defaultMessage: 'Chip Block',
  },
  ChipText: {
    id: 'Text',
    defaultMessage: 'Text',
  },
  Align: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
  innerAlign: {
    id: ' Inner Alignment',
    defaultMessage: 'Inner Alignment',
  },
});

export const ChipSchema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.ChipBlock),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['text', 'inneralign'],
      },
    ],

    properties: {
      text: {
        title: props.intl.formatMessage(messages.ChipText),
      },
      inneralign: {
        title: props.intl.formatMessage(messages.innerAlign),
        widget: 'inner_align',
        default: 'left',
      },
    },
    required: [],
  };
};
