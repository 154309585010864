import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { STYLE_VARIANT_WITH_SHADOW } from './schema';
import { View as IconView } from '../Icon/View';

export const IconInLeftTemplate = ({ data, isEditMode }) => {
  const hasLink = data.link && data.link.length > 0;
  return (
    <div
      className={`iskra-generic-card-wrapper ${
        data.style_variant === STYLE_VARIANT_WITH_SHADOW
          ? 'iskra-generic-card-wrapper__with-shadow'
          : ''
      }`}
    >
      <div className="iskra-two-columns-card-line">
        <IconView data={data} />
        <div>
          <div className="theme-body1-text text-font-weight-bold">
            <span>{data.title}</span>
          </div>
          <div className="iskra-card-subtitle">
            <div
              className="richtext widget"
              dangerouslySetInnerHTML={{
                __html: data?.description?.data ?? '',
              }}
            />
          </div>
        </div>
      </div>

      {hasLink && (
        <div className="iskra-card-subtitle">
          <ConditionalLink
            to={
              data.link.length > 0 ? flattenToAppURL(data.link[0]['@id']) : ''
            }
            condition={!isEditMode}
          >
            <span> {data.linkText}</span>
          </ConditionalLink>
        </div>
      )}
    </div>
  );
};
