import { Edit } from './Edit';
import { View } from './View';
import bookmarkSquareSVG from '../../assets/icons/bookmark-square.svg';
import { DefaultTemplate } from './DefaultTemplate';
import { IconInLeftTemplate } from './IconInLeftTemplate';
import { OneColumnTemplate } from './OneColumnTemplate';

export const cardBlock = {
  id: '__iskra-card',
  title: 'Iskra card',
  icon: bookmarkSquareSVG,
  group: 'common',
  view: View,
  edit: Edit,
  restricted: false,
  mostUsed: true,
  sidebarTab: 1,
  variations: [
    {
      id: 'card_default',
      isDefault: true,
      title: 'Card default',
      template: DefaultTemplate,
    },
    {
      id: 'icon_in_left',
      title: 'Icon in left',
      template: IconInLeftTemplate,
    },
    {
      id: 'one_column',
      title: 'One column',
      template: OneColumnTemplate,
    },
  ],
};
