/**
 * Contact Form container.
 * @module components/theme/ContactForm/ContactForm
 */

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Container, Message } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { emailNotification } from '@plone/volto/actions';
import { View as ButtonView } from 'web-publica-iskra-addons/components/Button/View';

import { contactFormMessages as messages } from '../../../../messages';

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const ContactFormPage = ({
  loading,
  intl,
  emailNotification,
  error,
}) => {
  const [validationErrors, setValidationErrors] = React.useState(null);
  return (
    <Container>
      <section className="mt-40">
        <div
          className="text-align-center theme-title-big-text text-font-weight-bold"
          style={{ maxWidth: '800px', margin: '0 auto', marginBottom: '40px' }}
        >
          {intl.formatMessage(messages.howCanWeHelp)}
        </div>
        <div
          className="text-align-center theme-title-small-text theme-gray-text-color"
          style={{ maxWidth: '800px', margin: '0 auto', marginBottom: '100px' }}
        >
          {intl.formatMessage(messages.howCanWeHelpSubtitle)}
        </div>
      </section>
      {error && (
        <section className="mb-24">
          <Message
            icon="warning"
            negative
            attached
            header={intl.formatMessage(messages.error)}
            content={error.message}
          />
        </section>
      )}
      <form
        className="iskra-contact-page-form"
        onSubmit={(e) => {
          e.preventDefault();
          const errors = {};
          const dataForm = new FormData(e.currentTarget);
          const parsedData = Object.fromEntries(dataForm);
          if (!parsedData.name) {
            errors['name'] = intl.formatMessage(messages.requiredField);
          }
          if (!parsedData.from) {
            errors['from'] = intl.formatMessage(messages.requiredField);
          }
          if (parsedData.from && !validateEmail(parsedData.from)) {
            errors['from'] = intl.formatMessage(messages.incorrectFormat);
          }
          if (!parsedData.message) {
            errors['message'] = intl.formatMessage(messages.requiredField);
          }
          setValidationErrors(errors);
          if (Object.keys(errors).length > 0) return;

          emailNotification(
            parsedData.from,
            parsedData.message,
            parsedData.name,
            'Formulari contacte, web iskra',
          );
        }}
      >
        <input name="name" placeholder={intl.formatMessage(messages.name)} />
        {validationErrors && validationErrors.name && (
          <p className="error-color theme-body3-text margin-zero">
            {validationErrors.name}
          </p>
        )}
        <input name="from" placeholder={intl.formatMessage(messages.from)} />
        {validationErrors && validationErrors.from && (
          <p className="error-color theme-body3-text margin-zero">
            {validationErrors.from}
          </p>
        )}
        <textarea
          name="message"
          rows={8}
          placeholder={intl.formatMessage(messages.message)}
        />
        {validationErrors && validationErrors.message && (
          <p className="error-color theme-body3-text margin-zero">
            {validationErrors.message}
          </p>
        )}
        <ButtonView
          data={{
            color: 'primary',
            size: 'small',
            inneralign: 'full',
            title: loading
              ? intl.formatMessage(messages.sending)
              : intl.formatMessage(messages.send),
            type: 'submit',
            disabled: loading,
          }}
        />
      </form>
      <section className="iskra-contact-page-footer">
        <div
          className="theme-title-big-text text-font-weight-bold "
          style={{ maxWidth: '800px', margin: '0 auto', marginBottom: '40px' }}
        >
          {intl.formatMessage(messages.bookAMeeting)}
        </div>
        <ButtonView
          data={{
            color: 'secondary',
            size: 'small',
            inneralign: 'right',
            title: intl.formatMessage(messages.bookingNow),
            href: [{ '@id': 'https://calendly.com/iskra-dev/20min' }],
          }}
        />
      </section>
    </Container>
  );
};

export default compose(
  withRouter,
  injectIntl,
  connect(
    (state, props) => ({
      loading: state.emailNotification.loading,
      loaded: state.emailNotification.loaded,
      error: state.emailNotification.error,
      pathname: props.location.pathname,
    }),
    { emailNotification },
  ),
)(ContactFormPage);
