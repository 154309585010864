import { Icon, SidebarPortal } from '@plone/volto/components';
import BlockDataForm from '@plone/volto/components/manage/Form/BlockDataForm';
import clearSVG from '@plone/volto/icons/clear.svg';
import config from '@plone/volto/registry';
import { useRef, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import { NewBlockAddButton } from '../NewBlockAddButton';
import { BlockRenderer } from '../BlockRenderer';
import schema from './schema';

function getAllowedBlocks(type) {
  return config.blocks.blocksConfig?.[type]?.allowedBlocks;
}

export const Edit = (props) => {
  const blocksConfig = props.blocksConfig;
  const [isChildSelected, setIsChildSelected] = useState(null);
  const node = useRef(null);

  const onChangeChild = (data, key) => {
    const newData = { ...data };
    if (!newData?.id) {
      newData.id = uuid();
    }
    props.onChangeBlock(props.block, {
      ...props.data,
      [key]: {
        ...newData,
      },
    });
  };
  const onResetChild = (key) => {
    props.onChangeBlock(props.block, {
      ...props.data,
      [key]: null,
    });
  };

  const renderChild = (key) => {
    return (
      <>
        {props.data[key] && (
          <Button
            aria-label={`Reset element`}
            basic
            icon
            onClick={(e) => onResetChild(key)}
            className="remove-block-button"
          >
            <Icon name={clearSVG} className="circled" size="24px" />
          </Button>
        )}
        {!props.data[key] && (
          <div className="uber-grid-default-item">
            <NewBlockAddButton
              block={props.blocks}
              onChangeGridItem={(data) => onChangeChild(data, key)}
              allowedBlocks={getAllowedBlocks(props.data['@type'])}
            />
          </div>
        )}
        {props.data[key] && (
          <BlockRenderer
            {...props}
            block={props.data[key].id}
            edit
            type={props.data[key]['@type']}
            selected={props.selected && isChildSelected === key}
            onChangeBlock={(block, data) => {
              onChangeChild(data, key);
            }}
            data={props.data[key]}
            blocksConfig={blocksConfig}
          />
        )}
      </>
    );
  };
  return (
    <div>
      <div
        ref={node}
        className="main-slider"
        role="presentation"
        onClick={(e) => {
          setIsChildSelected(null);
          node.current.focus();
        }}
      >
        <div className="content-main-slider">
          <h2 className="addon-test-title">
            {props.data.main_text
              ? props.data.main_text
              : 'Construïm <codi /> amb transparència'}
          </h2>
          <h3 className="addon-test-subtitle">
            {props.data.subtitle
              ? props.data.subtitle
              : 'Iskra és una empresa TIC, especialitzada en solucions tecnològiques i serveis IT per empreses i startups.'}
          </h3>
          <div className="wrapper-buttons">
            <div
              role="presentation"
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(e) => {
                e.stopPropagation();
                props.onSelectBlock(props.block);
                setIsChildSelected('firstButton');
              }}
            >
              {renderChild('firstButton')}
            </div>
            <div
              role="presentation"
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(e) => {
                e.stopPropagation();
                props.onSelectBlock(props.block);
                setIsChildSelected('secondButton');
              }}
            >
              {renderChild('secondButton')}
            </div>
          </div>
        </div>
      </div>

      <SidebarPortal selected={props.selected && isChildSelected === null}>
        <BlockDataForm
          schema={schema}
          title={schema.title}
          onChangeField={(id, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              [id]: value,
            });
          }}
          onChangeBlock={props.onChangeBlock}
          formData={props.data}
          block={props.block}
        />
      </SidebarPortal>
    </div>
  );
};
