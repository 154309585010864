import React from 'react';
import { RenderIcon } from './RenderIcon';
import PropTypes from 'prop-types';

export const View = ({ data }) => {
  const getIconSize = (size) => {
    switch (size) {
      case 'large':
        return '50px';
      case 'small':
        return '35px';
      case 'mini':
        return '20px';
      default:
        return '35px';
    }
  };

  return (
    <div
      className={`iskra-icon-wrapper iskra-icon-shape-${data.iconShape} iskra-icon-size-${data.iconSize}`}
      style={{
        backgroundColor: data.backgroundColor,
        color: data.iconColor,
        border: `1px solid ${data.borderColor}`,
      }}
    >
      <RenderIcon
        size={getIconSize(data.iconSize)}
        name={data.icon ?? 'block'}
      />
    </div>
  );
};

View.propTypes = {
  data: PropTypes.shape({
    iconShape: PropTypes.string,
    iconSize: PropTypes.string,
    borderColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string,
    icon: PropTypes.string,
  }),
};
